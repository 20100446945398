// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';


// custom actions
export const SET_PAGE_LOADING = 'SET_PAGE_LOADING';
export const setPageLoading = (value) => ({
    type: SET_PAGE_LOADING,
    payload : value
  });

// Action types
export const SET_USER_SESSION = 'SET_USER_SESSION';

// Action creator 
export function setUserSession(user) {
  // console.log(user);
  return {
    type: SET_USER_SESSION,
    payload: user  
  }
}

export const UPDATE_USER_SESSION = 'UPDATE_USER_SESSION';

// New action creator for updating user session
export function updateUserSession(updates) {
  return {
      type: UPDATE_USER_SESSION,
      payload: updates  
  }
}

export const UPDATE_RENEWAL_NOTIFICATION = 'UPDATE_RENEWAL_NOTIFICATION';

// New action creator for updating renewal notifications
export function updateRenewalNotification(notifications) {
  return {
      type: UPDATE_RENEWAL_NOTIFICATION,
      payload: notifications  
  }
}


export const UPDATE_PAYMENT_NOTIFICATION = 'UPDATE_PAYMENT_NOTIFICATION';

// New action creator for updating payment notifications
export function updatePaymentNotification(notifications) {
  return {
      type: UPDATE_PAYMENT_NOTIFICATION,
      payload: notifications  
  }
}

export const UPDATE_OTHER_NOTIFICATION = 'UPDATE_OTHER_NOTIFICATION';

// New action creator for updating other notifications
export function updateOtherNotification(notifications) {
  return {
      type: UPDATE_OTHER_NOTIFICATION,
      payload: notifications  
  }
}

export const UPDATE_MARK_ALL_AS_READ_OTHER_NOTIFICATION = 'UPDATE_MARK_ALL_AS_READ_OTHER_NOTIFICATION';

// New action creator for updating other notifications
export function updateMarkAllAsReadOtherNotification(notifications) {
  return {
      type: UPDATE_MARK_ALL_AS_READ_OTHER_NOTIFICATION,
      payload: notifications  
  }
}

export const UPDATE_CUSTOMERS = 'UPDATE_CUSTOMERS';

// New action creator for updating customers
export function updateCustomers(customers) {
  return {
      type: UPDATE_CUSTOMERS,
      payload: customers  
  }
}

export const UPDATE_SUSPENDED_PROPERTIES = 'UPDATE_SUSPENDED_PROPERTIES';

// New action creator for updating SUSPENDED_PROPERTIES
export function updateSuspendedProperties(suspendedProperties) {
  return {
      type: UPDATE_SUSPENDED_PROPERTIES,
      payload: suspendedProperties  
  }
}

// Action types
export const SET_SELECTED_ORGANIZATION = 'SET_SELECTED_ORGANIZATION';

// Action creator 
export function setSelectedOrganization(organization) {
  // console.log(organization);
  return {
    type: SET_SELECTED_ORGANIZATION,
    payload: organization  
  }
}


// Action types
export const UPDATE_ORGANIZATIONS = 'UPDATE_ORGANIZATIONS';

// Action creator 
export function updateOrganizations(organizations) {
  // console.log(organization);
  return {
    type: UPDATE_ORGANIZATIONS,
    payload: organizations
  }
}

// Action types
export const UPDATE_EXPIRED_SUBSCRIBERS = 'UPDATE_EXPIRED_SUBSCRIBERS';

// Action creator 
export function updateExpiredSubscribers(expiredSubscribers) {
  // console.log(organization);
  return {
    type: UPDATE_EXPIRED_SUBSCRIBERS,
    payload: expiredSubscribers
  }
}