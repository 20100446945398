// action - state management
import * as actionTypes from './actions';

var persistedSession = JSON.parse(window.localStorage.getItem(process.env.REACT_APP_NAME + '_user_session') ?? '{}');
persistedSession = persistedSession.token ? persistedSession : {};

var persistentRenewalNotifications = JSON.parse(window.localStorage.getItem(process.env.REACT_APP_NAME + '_renewal_notifications') ?? '[]');
persistentRenewalNotifications = persistentRenewalNotifications.length ? persistentRenewalNotifications : [];

var paymentNotifications = JSON.parse(window.localStorage.getItem(process.env.REACT_APP_NAME + '_payment_notifications') ?? '[]');
paymentNotifications = paymentNotifications.length ? paymentNotifications : [];

var persistentOtherNotifications = JSON.parse(window.localStorage.getItem(process.env.REACT_APP_NAME + '_other_notifications') ?? '[]');
persistentOtherNotifications = persistentOtherNotifications.length ? persistentOtherNotifications : [];

var customers = JSON.parse(window.localStorage.getItem(process.env.REACT_APP_NAME + '_customers') ?? '[]');
customers = customers.length ? customers : [];

var suspendedProperties = window.localStorage.getItem(process.env.REACT_APP_NAME + '_suspended_properties') ?? 0;

var organizations = JSON.parse(window.localStorage.getItem(process.env.REACT_APP_NAME + '_organizations') ?? '[]');
organizations = organizations.length ? organizations : [];

var selectedOrganization = JSON.parse(window.localStorage.getItem(process.env.REACT_APP_NAME + '_selected_organization') ?? '{}');
selectedOrganization = selectedOrganization.id ? selectedOrganization : {};

var expiredSubscribers = window.localStorage.getItem(process.env.REACT_APP_NAME + '_expired_subscribers') ?? 0;
// console.log(process.env.REACT_APP_NAME+'_other_notifications')
export const initialState = {
  isPageLoading: false,
  user: persistedSession,
  renewalNotifications: persistentRenewalNotifications,
  paymentNotifications: paymentNotifications,
  otherNotifications: persistentOtherNotifications,
  customers: customers,
  suspendedProperties: suspendedProperties,
  selectedOrganization: selectedOrganization,
  organizations: organizations,
  expiredSubscribers: expiredSubscribers
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const appReducer = (state = initialState, action) => {
  let id;
  switch (action.type) {
    case actionTypes.SET_PAGE_LOADING:
      return {
        ...state,
        isPageLoading: action.payload
      };
    case actionTypes.SET_USER_SESSION:
      return {
        ...state,
        user: action.payload
      };
    case actionTypes.UPDATE_USER_SESSION:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      };
    case actionTypes.UPDATE_RENEWAL_NOTIFICATION:
      return {
        ...state,
        renewalNotifications: action.payload
      };
    case actionTypes.UPDATE_PAYMENT_NOTIFICATION:
      return {
        ...state,
        paymentNotifications: action.payload
      };
    case actionTypes.UPDATE_OTHER_NOTIFICATION: {
      const newNotifications = action.payload;
      const updatedNotifications = newNotifications.map((notif) => {
        const existingNotif = state.otherNotifications.find(
          (n) => n.id === notif.id
        );
        return existingNotif
          ? existingNotif // Keep existing notification (preserve isRead)
          : { ...notif, isRead: false }; // New notification, mark as unread
      });

      return {
        ...state,
        otherNotifications: updatedNotifications,
      };
    }
    case actionTypes.UPDATE_MARK_ALL_AS_READ_OTHER_NOTIFICATION:
      return {
        ...state,
        otherNotifications: state.otherNotifications.map((notif) => ({
          ...notif,
          isRead: true,
        })),
      };
    case actionTypes.UPDATE_CUSTOMERS:
      return {
        ...state,
        customers: action.payload
      };
    case actionTypes.UPDATE_SUSPENDED_PROPERTIES:
      return {
        ...state,
        suspendedProperties: action.payload
      };
    case actionTypes.SET_SELECTED_ORGANIZATION:
      return {
        ...state,
        selectedOrganization: action.payload
      };
    case actionTypes.UPDATE_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload
      };
    case actionTypes.UPDATE_EXPIRED_SUBSCRIBERS:
      return {
        ...state,
        expiredSubscribers: action.payload
      };
    default:
      return state;
  }
};

export default appReducer;
